import * as Yup from 'yup';
import { convertToHTML } from 'draft-convert';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

export function getDynamicContactInfoFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      ...dynamicFields,
      [`footer${item[1]}`]: '',
      [`copyright${item[1]}`]: '',
    };
  });
  return dynamicFields;
}
export function getLocationFields() {
  let dynamicFields = {};
  LANGUAGE_BY_DOMAIN.forEach(item => {
    dynamicFields = {
      imageUrl: '',
      address: '',
      mapLink: '',
      locationTitle: '',
      openingFields: [
        {
          openingDay: '',
          openingTime: '',
        },
      ],
    };
  });
  return dynamicFields;
}

function getInitialFormData() {
  const dynamicFields = getDynamicContactInfoFields();
  const locationFields = getLocationFields();
  const initialFormData = {
    isMultiLang: 'false',
    fullName: '',
    email: '',
    code: null,
    number: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedIn: '',
    youtube: '',
    tiktok: '',
    map: '',
    location: [{ ...locationFields }],
    ...dynamicFields,
  };

  return initialFormData;
}

export const contactInfoInitialFormData = getInitialFormData();

export function contactInfoValidationSchema(isMultiLangFlag) {
  const locationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required!'),
    mapLink: Yup.string()
      .url('Invalid map link URL!')
      .required('Map link is required!'),
    locationTitle: Yup.string().required('Title is required!'),
    imageUrl: Yup.mixed()
      .test('is-valid-file-or-url', 'Invalid file or URL!', value => {
        if (!value) return false;
        if (Array.isArray(value)) {
          return value.length > 0 && value.every(file => file && typeof file.name === 'string');
        }
        return typeof value === 'string' && /^https?:\/\/\S+$/.test(value);
      })
      .required('Image is required!'),

    openingFields: Yup.array()
      .of(
        Yup.object().shape({
          openingDay: Yup.string().required('Opening day is required!'),
          openingTime: Yup.string().required('Opening time is required!'),
        }),
      )
      .min(1, 'At least one opening field is required!'),
  });

  let validationFields = {
    [`footer${DEFAULT_LANG.value}`]: Yup.mixed().required(`Footer(${DEFAULT_LANG.label}) is required!`),
    [`copyright${DEFAULT_LANG.value}`]: Yup.mixed()
      // eslint-disable-next-line func-names
      .test('copyright', '', function(fieldValue) {
        const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
        if (isFieldEmpty === '<p></p>') {
          return this.createError({
            message: `Description(${DEFAULT_LANG.label}) is required!`,
          });
        }
        return true;
      })
      .required(`Description(${DEFAULT_LANG.label}) is required!`),
  };

  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
    if (isMultiLangFlag) {
      validationFields = {
        ...validationFields,
        [`footer${value}`]: Yup.string().required(`Footer(${label}) is required!`),
        [`copyright${value}`]: Yup.mixed()
          // eslint-disable-next-line func-names
          .test('copyright', '', function(fieldValue) {
            const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
            if (isFieldEmpty === '<p></p>') {
              return this.createError({
                message: `Description(${DEFAULT_LANG.label}) is required!`,
              });
            }
            return true;
          })
          .required(`Description(${label}) is required!`),
      };
    } else {
      validationFields = {
        ...validationFields,
        [`footer${value}`]: Yup.string(),
        [`copyright${value}`]: Yup.mixed(),
      };
    }
  });

  return Yup.object().shape({
    isMultiLang: Yup.string(),
    fullName: Yup.string().required('Please input full name!'),
    email: Yup.string()
      .email('Invalid email format!')
      .required('Please input email!'),
    code: Yup.string()
      .nullable()
      .required('Country code is required!'),
    number: Yup.string().required('Phone number is required!'),
    whatsapp: Yup.string().required('Whatsapp number with country code is required!'),
    facebook: Yup.string().url('Invalid facebook url!'),
    instagram: Yup.string().url('Invalid instagram url!'),
    twitter: Yup.string().url('Invalid twitter url!'),
    linkedIn: Yup.string().url('Invalid linkedin url!'),
    youtube: Yup.string().url('Invalid youtube url!'),
    tiktok: Yup.string().url('Invalid tiktok url!'),
    map: Yup.string(),
    location: Yup.array().of(locationSchema),
    ...validationFields,
  });
}

// import * as Yup from 'yup';
// import { convertToHTML } from 'draft-convert';
// import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';

// // Helper function to generate dynamic fields for contact info and locations based on language domains
// function generateDynamicFields(fields, prefix, fieldsToGenerate) {
//   let dynamicFields = {};
//   LANGUAGE_BY_DOMAIN.forEach(item => {
//     fieldsToGenerate.forEach(field => {
//       dynamicFields = {
//         ...dynamicFields,
//         [`${prefix}${field}${item[1]}`]: '', // Dynamic field generation
//       };
//     });
//   });
//   return dynamicFields;
// }

// // Function to generate dynamic contact info fields
// export function getDynamicContactInfoFields() {
//   const fieldsToGenerate = ['footer', 'copyright']; // Fields for dynamic contact info
//   return generateDynamicFields({}, 'footer', fieldsToGenerate);
// }

// // Function to generate dynamic location fields
// export function getLocationFields() {
//   const fieldsToGenerate = ['address', 'mapLink', 'locationTitle']; // Fields for dynamic location info
//   return generateDynamicFields({}, 'location', fieldsToGenerate);
// }

// // Function to generate initial form data with dynamic fields
// function getInitialFormData() {
//   const dynamicFields = getDynamicContactInfoFields();
//   const locationFields = getLocationFields();

//   const initialFormData = {
//     isMultiLang: 'false',
//     fullName: '',
//     email: '',
//     code: null,
//     number: '',
//     whatsapp: '',
//     facebook: '',
//     instagram: '',
//     twitter: '',
//     linkedIn: '',
//     youtube: '',
//     tiktok: '',
//     map: '',
//     location: [{ ...locationFields }],
//     ...dynamicFields, // Spread dynamic contact info fields here
//   };

//   return initialFormData;
// }

// // Default contact info initial form data
// export const contactInfoInitialFormData = getInitialFormData();

// // Location validation schema
// const locationSchema = Yup.object().shape({
//   address: Yup.string().required('Address is required!'),
//   mapLink: Yup.string()
//     .url('Invalid map link URL!')
//     .required('Map link is required!'),
//   locationTitle: Yup.string().required('Title is required!'),
// });

// // Function to generate validation schema for contact info
// export function contactInfoValidationSchema(isMultiLangFlag) {
//   // Validation for dynamic footer and copyright fields
//   let validationFields = {
//     [`footer${DEFAULT_LANG.value}`]: Yup.string().required(`Footer(${DEFAULT_LANG.label}) is required!`),
//     [`copyright${DEFAULT_LANG.value}`]: Yup.mixed()
//       .test('copyright', '', function(fieldValue) {
//         const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
//         if (isFieldEmpty === '<p></p>') {
//           return this.createError({
//             message: `Description(${DEFAULT_LANG.label}) is required!`,
//           });
//         }
//         return true;
//       })
//       .required(`Description(${DEFAULT_LANG.label}) is required!`),
//   };

//   // Adding validation for additional languages based on the isMultiLangFlag
//   LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).forEach(([label, value]) => {
//     if (isMultiLangFlag) {
//       validationFields = {
//         ...validationFields,
//         [`footer${value}`]: Yup.string().required(`Footer(${label}) is required!`),
//         [`copyright${value}`]: Yup.mixed()
//           .test('copyright', '', function(fieldValue) {
//             const isFieldEmpty = convertToHTML(fieldValue.getCurrentContent());
//             if (isFieldEmpty === '<p></p>') {
//               return this.createError({
//                 message: `Description(${label}) is required!`,
//               });
//             }
//             return true;
//           })
//           .required(`Description(${label}) is required!`),
//       };
//     } else {
//       validationFields = {
//         ...validationFields,
//         [`footer${value}`]: Yup.string(),
//         [`copyright${value}`]: Yup.mixed(),
//       };
//     }
//   });

//   // Full form validation schema
//   return Yup.object().shape({
//     isMultiLang: Yup.string(),
//     fullName: Yup.string().required('Please input full name!'),
//     email: Yup.string()
//       .email('Invalid email format!')
//       .required('Please input email!'),
//     code: Yup.string()
//       .nullable()
//       .required('Country code is required!'),
//     number: Yup.string().required('Phone number is required!'),
//     whatsapp: Yup.string().required('Whatsapp number with country code is required!'),
//     facebook: Yup.string()
//       .url('Invalid facebook url!')
//       .notRequired(),
//     instagram: Yup.string()
//       .url('Invalid instagram url!')
//       .notRequired(),
//     twitter: Yup.string()
//       .url('Invalid twitter url!')
//       .notRequired(),
//     linkedIn: Yup.string()
//       .url('Invalid linkedin url!')
//       .notRequired(),
//     youtube: Yup.string()
//       .url('Invalid youtube url!')
//       .notRequired(),
//     tiktok: Yup.string()
//       .url('Invalid tiktok url!')
//       .notRequired(),
//     map: Yup.string(),
//     location: Yup.array().of(locationSchema),
//     ...validationFields, // Spread dynamic validation fields here
//   });
// }
