/* eslint-disable no-underscore-dangle */
import { Col, Image, Row, Switch } from 'antd';
import { FieldArray, Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertToHTML } from 'draft-convert';
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { contactInfoValidationSchema } from './contactInfo.schema';
import { Button } from '../../../components/buttons/buttons';
import FormInput from '../../../components/input/input';
import FormSelect from '../../../components/select/FormSelect';
import {
  addNewContactInformation,
  editContactInformation,
  getAllContactInformations,
} from '../../../redux/contact information/actionCreator';
import { validatePhoneNumber } from '../../../utility/utility';
import { DEFAULT_LANG, LANGUAGE_BY_DOMAIN } from '../../../utils/constants';
import CountryCode from '../../../utils/countryCode.json';
import { BasicFormWrapper } from '../../styled';
import DivBreak from '../../../components/divBreak/DivBreak';
import FormUpload from '../../../components/upload/FormUpload';
import { useHistory } from 'react-router-dom';

const ContactInfoForm = props => {
  const { formData, onRefresh, oldLocations } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: contactInfoLoading } = useSelector(state => state.contactInformation);

  const [countries] = useState(CountryCode);

  const [isMultiLangFlag, setIsMultiLangFlag] = useState(false);

  useEffect(() => {
    setIsMultiLangFlag(formData?.isMultiLang.includes('true'));
  }, [formData]);

  function getCountryDropdownOpt() {
    const dropdownOpt = countries?.map(country => ({
      value: country?.dial_code ?? '',
      key: `${country?.name}${country?.dial_code}`,
      label: `${' '}${country?.name}(${country?.dial_code})`,
    }));
    return dropdownOpt ?? [];
  }

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  async function handleFormSubmit(requestData, resetForm) {
    const formValue = new FormData();

    try {
      const transformedCopyrightAndFooter = {
        transformedCopyright: [],
        transformedFooter: [],
      };

      const locationData = [...requestData?.location];

      const imageMapping = [];

      const transformedLocation = locationData.map((location, idx) => {
        const { imageUrl, ...updatedLocation } = location;

        const oldImg = oldLocations.find(loc => loc._id === location._id);

        if (location?.imageUrl && location?.imageUrl[0]?.originFileObj) {
          formValue.append('images', location.imageUrl[0].originFileObj);
          imageMapping.push({ index: idx, fileName: location.imageUrl[0].name });
          updatedLocation.imageIndex = imageMapping.length - 1;
        }

        updatedLocation.imageUrl = oldImg?.imageUrl;

        return updatedLocation;
      });

      let apiReqData = {
        fullName: requestData.fullName,
        email: requestData.email,
        code: requestData.code,
        number: requestData.number,
        whatsapp: requestData.whatsapp,
        map: requestData?.map,
        facebook: requestData?.facebook,
        instagram: requestData?.instagram,
        twitter: requestData?.twitter,
        linkedIn: requestData?.linkedIn,
        youtube: requestData?.youtube,
        tiktok: requestData?.tiktok,
        location: transformedLocation,
      };

      LANGUAGE_BY_DOMAIN.forEach(langItem => {
        if (requestData.isMultiLang === 'true' || langItem[1] === DEFAULT_LANG.value) {
          transformedCopyrightAndFooter.transformedFooter.push({
            lang: langItem[1],
            footer: requestData[`footer${langItem[1]}`],
          });
          transformedCopyrightAndFooter.transformedCopyright.push({
            lang: langItem[1],
            copyright: convertToHTML(requestData[`copyright${langItem[1]}`]?.getCurrentContent()),
          });
        }
      });
      apiReqData = {
        ...apiReqData,
        copyright_details: JSON.stringify(transformedCopyrightAndFooter.transformedCopyright),
        footer_details: JSON.stringify(transformedCopyrightAndFooter.transformedFooter),
      };

      formValue.append('details', JSON.stringify(apiReqData));
      // eslint-disable-next-line no-underscore-dangle
      const res = await dispatch(
        requestData?._id ? editContactInformation(requestData?._id, formValue) : addNewContactInformation(apiReqData),
      );
      if (res?.data?.status) {
        // resetForm();
        // getAllContactInformations(dispatch);
        handleRefresh();
        // history.push('/admin/contact-information');
      }
    } catch (err) {
      console.log(err);
    }
  }

  const goback = () => {
    history.push('/admin/contact-information');
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={contactInfoValidationSchema(isMultiLangFlag)}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        // eslint-disable-next-line no-use-before-define
        handleFormSubmit(values, resetForm);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, setFieldValue, setErrors }) => {
        return (
          <Form>
            <BasicFormWrapper>
              <div className="add-product-block">
                <Row gutter={[16, 16]}>
                  {/* language switcher */}
                  <Col xs={24}>
                    <h4>Translate</h4>
                    <Switch
                      checked={values.isMultiLang === 'true'}
                      onChange={checked => {
                        setFieldValue('isMultiLang', String(checked));
                        setIsMultiLangFlag(checked);
                      }}
                    />
                  </Col>

                  {/* fullName */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="fullName"
                      label="Full Name"
                      value={values.fullName}
                      handleChange={handleChange}
                      placeholder="Full Name"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* email */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="email"
                      label="Email"
                      value={values.email}
                      handleChange={handleChange}
                      placeholder="Email"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/* country code */}
                  <Col xs={24} sm={12} md={8}>
                    <FormSelect
                      name="code"
                      label="Code"
                      value={values.code}
                      options={getCountryDropdownOpt() ?? []}
                      handleChange={value => {
                        setFieldValue('code', value);
                      }}
                      placeholder="Code"
                      errors={errors}
                      touched={touched}
                      allowClear
                    />
                  </Col>

                  {/*  phoneNumber */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="number"
                      label="Phone Number"
                      value={values.number}
                      handleChange={event => {
                        const errorMsg = 'Number is invalid!';
                        const fieldValue = event?.target?.value;
                        validatePhoneNumber({
                          fieldName: 'number',
                          fieldValue,
                          setFieldValue,
                          errors,
                          setErrors,
                          errorMsg,
                        });
                      }}
                      placeholder="Phone number"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>

                  {/*  whatsapp */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="whatsapp"
                      label="Whatsapp"
                      value={values.whatsapp}
                      handleChange={event => {
                        const errorMsg = 'Number is invalid!';
                        const fieldValue = event?.target?.value;
                        validatePhoneNumber({
                          fieldName: 'whatsapp',
                          fieldValue,
                          setFieldValue,
                          errors,
                          setErrors,
                          errorMsg,
                        });
                      }}
                      placeholder="Whatsapp"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  {/* Map */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="map"
                      label="Map"
                      value={values.map}
                      handleChange={handleChange}
                      placeholder="Map link"
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>

                <DivBreak />

                <h3>Social Links</h3>
                <Row gutter={[16, 16]}>
                  {/*  facebook */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="facebook"
                      label="Facebook"
                      value={values.facebook}
                      handleChange={handleChange}
                      placeholder="Facebook Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  instagram */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="instagram"
                      label="Instagram"
                      value={values.instagram}
                      handleChange={handleChange}
                      placeholder="Instagram Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  twitter */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="twitter"
                      label="Twitter"
                      value={values.twitter}
                      handleChange={handleChange}
                      placeholder="Twitter Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  linkedIn */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="linkedIn"
                      label="LinkedIn"
                      value={values.linkedIn}
                      handleChange={handleChange}
                      placeholder="LinkedIn Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  youtube */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="youtube"
                      label="Youtube"
                      value={values.youtube}
                      handleChange={handleChange}
                      placeholder="Youtube Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>

                  {/*  tiktok */}
                  <Col xs={24} sm={12} md={8}>
                    <FormInput
                      name="tiktok"
                      label="Tiktok"
                      value={values.tiktok}
                      handleChange={handleChange}
                      placeholder="Tiktok Link"
                      errors={errors}
                      touched={touched}
                      isRequired={false}
                    />
                  </Col>
                </Row>

                <h4 style={{ margin: '16px 0' }}>Location</h4>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <FieldArray
                      name="location"
                      render={outerHelpers => {
                        const { push: pushLocation, remove: removeLocation, form } = outerHelpers;
                        return (
                          <>
                            {form.values.location.map((locationItem, locationIndex) => (
                              <>
                                <Row
                                  key={locationIndex}
                                  gutter={[16, 16]}
                                  style={{
                                    marginBottom: '16px',
                                    background: '#f4f4f4',
                                    padding: '20px',
                                    borderRadius: '10px',
                                  }}
                                >
                                  {/* Lacation input */}
                                  <Col xs={24} sm={12} md={8}>
                                    <FormInput
                                      name={'locationTitle'}
                                      label={`Location Title ${locationIndex + 1}`}
                                      value={locationItem.locationTitle}
                                      handleChange={event =>
                                        form.setFieldValue(
                                          `location[${locationIndex}].locationTitle`,
                                          event.target.value,
                                        )
                                      }
                                      placeholder="Location"
                                      errors={form.errors.location?.[locationIndex]}
                                      touched={form.touched.location?.[locationIndex]}
                                      // isRequired={locationIndex === 0}
                                    />
                                  </Col>

                                  {/* Address Input */}
                                  <Col xs={24} sm={12} md={8}>
                                    <FormInput
                                      name={`address`}
                                      label={`Address ${locationIndex + 1}`}
                                      value={locationItem.address}
                                      handleChange={event =>
                                        form.setFieldValue(`location[${locationIndex}].address`, event.target.value)
                                      }
                                      placeholder="Address"
                                      errors={form.errors.location?.[locationIndex]}
                                      touched={form.touched.location?.[locationIndex]}
                                      // isRequired={locationIndex === 0}
                                    />
                                  </Col>

                                  {/* Map Link Input */}
                                  <Col xs={24} sm={12} md={8}>
                                    <FormInput
                                      name={'mapLink'}
                                      label={`Maplink ${locationIndex + 1}`}
                                      value={locationItem.mapLink}
                                      handleChange={event =>
                                        form.setFieldValue(`location[${locationIndex}].mapLink`, event.target.value)
                                      }
                                      placeholder="Maplink"
                                      errors={form.errors.location?.[locationIndex]}
                                      touched={form.touched.location?.[locationIndex]}
                                      // isRequired={locationIndex === 0}
                                    />
                                  </Col>
                                  {/* <Row gutter={[19, 16]}> */}
                                  <Col xs={24} sm={12} md={8}>
                                    <FormUpload
                                      name="imageUrl"
                                      label="Image"
                                      value={locationItem.imageUrl}
                                      handleChange={info => {
                                        const fileList = [...info.fileList].slice(-1);

                                        setFieldValue(
                                          `location[${locationIndex}].imageUrl`,
                                          fileList.length > 0 ? [fileList[0]] : null,
                                        );
                                      }}
                                      accept="image/*"
                                      errors={form.errors.location?.[locationIndex]}
                                      touched={form.touched.location?.[locationIndex]}
                                      isRequired={true}
                                    />
                                  </Col>

                                  {/* Nested FieldArray for Opening Fields */}
                                  <Col xs={24} sm={24} md={16}>
                                    {/* Opening Hours */}
                                    <FieldArray
                                      name={`location[${locationIndex}].openingFields`}
                                      render={innerHelpers => {
                                        const { push: pushOpening, remove: removeOpening } = innerHelpers;
                                        return (
                                          <>
                                            {locationItem.openingFields?.map((openingItem, openingIndex) => (
                                              <Row
                                                key={openingIndex}
                                                gutter={[16, 16]}
                                                style={{
                                                  marginBottom: '10px',
                                                  // background: '#e2e3e5',
                                                  borderRadius: '10px',
                                                }}
                                              >
                                                {/* Opening Day Input */}
                                                <Col xs={10} sm={11}>
                                                  <FormInput
                                                    name={'openingDay'}
                                                    label={'Opening Days'}
                                                    value={openingItem.openingDay}
                                                    handleChange={event =>
                                                      form.setFieldValue(
                                                        `location[${locationIndex}].openingFields[${openingIndex}].openingDay`,
                                                        event.target.value,
                                                      )
                                                    }
                                                    placeholder="Opening Day"
                                                    errors={
                                                      form.errors.location?.[locationIndex]?.openingFields?.[
                                                        openingIndex
                                                      ]
                                                    }
                                                    touched={
                                                      form.touched.location?.[locationIndex]?.openingFields?.[
                                                        openingIndex
                                                      ]
                                                    }
                                                    // isRequired={false}
                                                  />
                                                </Col>

                                                {/* Opening Time Input */}
                                                <Col xs={10} sm={11}>
                                                  <FormInput
                                                    name={'openingTime'}
                                                    label={'Opening Time'}
                                                    value={openingItem.openingTime}
                                                    handleChange={event =>
                                                      form.setFieldValue(
                                                        `location[${locationIndex}].openingFields[${openingIndex}].openingTime`,
                                                        event.target.value,
                                                      )
                                                    }
                                                    placeholder="Opening Time"
                                                    errors={
                                                      form.errors.location?.[locationIndex]?.openingFields?.[
                                                        openingIndex
                                                      ]
                                                    }
                                                    touched={
                                                      form.touched.location?.[locationIndex]?.openingFields?.[
                                                        openingIndex
                                                      ]
                                                    }
                                                    // isRequired={false}
                                                  />
                                                </Col>

                                                {/* Remove Opening Field */}
                                                <Col
                                                  xs={2}
                                                  style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    paddingTop: '50px',
                                                  }}
                                                >
                                                  {locationItem.openingFields?.length > 1 && (
                                                    <MinusCircleOutlined onClick={() => removeOpening(openingIndex)} />
                                                  )}
                                                  {locationItem.openingFields?.length === openingIndex + 1 && (
                                                    <PlusCircleOutlined
                                                      onClick={() => pushOpening({ openingDay: '', openingTime: '' })}
                                                    />
                                                  )}
                                                </Col>
                                              </Row>
                                            ))}
                                          </>
                                        );
                                      }}
                                    />
                                  </Col>
                                  {/* </Row> */}
                                  {/* Remove Location */}
                                  {form.values.location.length > 1 && (
                                    <Col
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        width: '100%',
                                      }}
                                    >
                                      <Button
                                        type="dashed"
                                        onClick={() => removeLocation(locationIndex)}
                                        icon={<MinusCircleOutlined />}
                                      >
                                        Remove Location Field
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              </>
                            ))}

                            {/* Add Location */}
                            <Row justify="end">
                              <Col>
                                <Button
                                  type="dashed"
                                  onClick={() =>
                                    pushLocation({
                                      locationTitle: '',
                                      address: '',
                                      mapLink: '',
                                      openingFields: [{ openingDay: '', openingTime: '' }],
                                    })
                                  }
                                  icon={<PlusOutlined />}
                                >
                                  Add Location
                                </Button>
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                    />
                  </Col>

                  {/*  map */}
                </Row>
              </div>

              <DivBreak />

              <h3>Footer Texts</h3>
              <Row gutter={[16, 16]}>
                {/*  footer default */}
                <Col xs={24}>
                  <FormInput
                    type="textarea"
                    name={`footer${DEFAULT_LANG.value}`}
                    label={`Footer Text (${DEFAULT_LANG.label})`}
                    value={values[`footer${DEFAULT_LANG.value}`]}
                    handleChange={handleChange}
                    placeholder="Footer Text"
                    errors={errors}
                    touched={touched}
                  />
                </Col>

                {/* footer dynamic  */}
                {values.isMultiLang === 'true' &&
                  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                    return (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="textarea"
                          name={`footer${value}`}
                          label={`Footer Text (${label})`}
                          value={values[`footer${value}`]}
                          handleChange={handleChange}
                          placeholder="Footer Text"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    );
                  })}

                {/*  copyright description default */}
                <Col xs={24}>
                  <FormInput
                    type="editor"
                    name={`copyright${DEFAULT_LANG.value}`}
                    label={`Copyright Description (${DEFAULT_LANG.label})`}
                    editorState={values[`copyright${DEFAULT_LANG.value}`]}
                    handleEditorStateChange={state => {
                      const desc = `copyright${DEFAULT_LANG.value}`;
                      setFieldValue(desc, state);
                    }}
                    placeholder="Description"
                    errors={errors}
                    touched={touched}
                  />
                </Col>

                {/* copyright dynamic  */}
                {values.isMultiLang === 'true' &&
                  LANGUAGE_BY_DOMAIN.filter(item => DEFAULT_LANG.value !== item[1]).map(([label, value], index) => {
                    return (
                      <Col xs={24} key={index}>
                        <FormInput
                          type="editor"
                          name={`copyright${value}`}
                          label={`Copyright Description (${label})`}
                          editorState={values[`copyright${value}`]}
                          handleEditorStateChange={state => {
                            const desc = `copyright${value}`;
                            setFieldValue(desc, state);
                          }}
                          placeholder="Description"
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    );
                  })}
              </Row>

              <DivBreak />

              <Row justify="end" align="middle">
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#155BDC',
                      color: '#fff',
                      marginRight: 5,
                      height: '40px',
                      fontWeight: '500',
                    }}
                    htmlType="submit"
                    loading={contactInfoLoading}
                  >
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {values?._id ? 'Update Contact Info' : 'Add Contact Info'}
                  </Button>
                  <Button size="default" type="white" key="back" outlined onClick={goback}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </BasicFormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

ContactInfoForm.propTypes = {
  formData: propTypes.object,
  onCancel: propTypes.func,
};

export default ContactInfoForm;
